/**
 *
 * @param {AbortSignal} signal
 * @param {Promise<any>} promise
 * @returns {Promise<any>}
 */
export function abortSignalWrapper(signal, promise) {
  if (signal) {
    return new Promise((resolve, reject) => {
      signal.addEventListener("abort", reject);
      if (signal.aborted) reject();
      promise.then(resolve).catch(reject);
    });
  }
  return promise;
}
