import { abortSignalWrapper } from "@/js/utils/signal";

const CHUNK_SIZE = 1000;
const REQUEST_COUNT = 1;

/**
 * @param {Array} data
 * @param {Object} options
 * @param {Number=} options.chunkSize
 * @param {Number=} options.requestCount
 * @param {AbortSignal=} options.signal
 * @param {(Number) => Any} options.onProgress
 * @returns {Promise<Array>}
 */
export async function chunkAndParallelizeWrapper(fetcher, data, options) {
  const { chunkSize, requestCount, signal, onProgress } = Object.assign(
    { chunkSize: CHUNK_SIZE, requestCount: REQUEST_COUNT, onProgress: () => {} },
    options,
  );
  const chunks = splitArrayInChunks(data, chunkSize, requestCount);
  return await parallelCall(fetcher, chunks, { chunkSize, requestCount, signal, onProgress });
}

/**
 * @param {Array<Array>} chunks
 * @param {Object} options
 * @param {AbortSignal} options.signal
 * @param {Number} options.chunkSize
 * @param {Number} options.requestCount
 * @param {Function} options.onProgress
 * @return {Promise<Array>}
 */
async function parallelCall(fetcher, chunks, { requestCount, signal, onProgress }) {
  let result = [];
  for (const chunk of chunks) {
    const fetchedData = await abortSignalWrapper(signal, Promise.all(chunk.map(fetcher)));
    const progressCount = chunk.reduce((acc, data) => acc + data.length, 0);
    onProgress(progressCount);
    result = fetchedData.reduce((acc, data) => acc.concat(data), result);
  }
  return result;
}

/**
 * @param {Array} data
 * @param {Number} chunkSize
 * @returns {Array<Array>}
 */
function splitArrayInChunks(data, chunkSize, requestCount) {
  const chunks = [];
  for (let i = 0; i * chunkSize < data.length; i += 1) {
    const chunk = data.slice(i * chunkSize, (i + 1) * chunkSize);
    if (i % requestCount === 0) chunks.push([chunk]);
    else chunks.at(-1).push(chunk);
  }
  return chunks;
}
