/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MetaOut model module.
 * @module model/MetaOut
 * @version 1.0.0
 */
class MetaOut {
    /**
     * Constructs a new <code>MetaOut</code>.
     * @alias module:model/MetaOut
     * @param width {Number} 
     * @param height {Number} 
     */
    constructor(width, height) { 
        
        MetaOut.initialize(this, width, height);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, width, height) { 
        obj['width'] = width;
        obj['height'] = height;
    }

    /**
     * Constructs a <code>MetaOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MetaOut} obj Optional instance to populate.
     * @return {module:model/MetaOut} The populated <code>MetaOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MetaOut();

            if (data.hasOwnProperty('width')) {
                obj['width'] = ApiClient.convertToType(data['width'], 'Number');
            }
            if (data.hasOwnProperty('height')) {
                obj['height'] = ApiClient.convertToType(data['height'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MetaOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MetaOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of MetaOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }

        return true;
    }


}

MetaOut.RequiredProperties = ["width", "height"];

/**
 * @member {Number} width
 */
MetaOut.prototype['width'] = undefined;

/**
 * @member {Number} height
 */
MetaOut.prototype['height'] = undefined;






export default MetaOut;

