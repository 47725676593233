/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MetadataOut model module.
 * @module model/MetadataOut
 * @version 1.0.0
 */
class MetadataOut {
    /**
     * Constructs a new <code>MetadataOut</code>.
     * @alias module:model/MetadataOut
     * @param altitude {Number} 
     * @param acquiredAt {Date} 
     * @param acquiredBy {String} 
     * @param weather {String} 
     * @param resolutionX {Number} 
     * @param resolutionY {Number} 
     * @param resolutionUnit {String} 
     * @param compression {String} 
     * @param manufacturer {String} 
     * @param software {String} 
     * @param colorSpace {String} 
     * @param customId {String} 
     * @param reference {String} 
     * @param yaw {Number} 
     * @param pitch {Number} 
     * @param roll {Number} 
     * @param focalLength {Number} 
     * @param sensorWidth {Number} 
     */
    constructor(altitude, acquiredAt, acquiredBy, weather, resolutionX, resolutionY, resolutionUnit, compression, manufacturer, software, colorSpace, customId, reference, yaw, pitch, roll, focalLength, sensorWidth) { 
        
        MetadataOut.initialize(this, altitude, acquiredAt, acquiredBy, weather, resolutionX, resolutionY, resolutionUnit, compression, manufacturer, software, colorSpace, customId, reference, yaw, pitch, roll, focalLength, sensorWidth);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, altitude, acquiredAt, acquiredBy, weather, resolutionX, resolutionY, resolutionUnit, compression, manufacturer, software, colorSpace, customId, reference, yaw, pitch, roll, focalLength, sensorWidth) { 
        obj['altitude'] = altitude;
        obj['acquired_at'] = acquiredAt;
        obj['acquired_by'] = acquiredBy;
        obj['weather'] = weather;
        obj['resolution_x'] = resolutionX;
        obj['resolution_y'] = resolutionY;
        obj['resolution_unit'] = resolutionUnit;
        obj['compression'] = compression;
        obj['manufacturer'] = manufacturer;
        obj['software'] = software;
        obj['color_space'] = colorSpace;
        obj['custom_id'] = customId;
        obj['reference'] = reference;
        obj['yaw'] = yaw;
        obj['pitch'] = pitch;
        obj['roll'] = roll;
        obj['focal_length'] = focalLength;
        obj['sensor_width'] = sensorWidth;
    }

    /**
     * Constructs a <code>MetadataOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MetadataOut} obj Optional instance to populate.
     * @return {module:model/MetadataOut} The populated <code>MetadataOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MetadataOut();

            if (data.hasOwnProperty('altitude')) {
                obj['altitude'] = ApiClient.convertToType(data['altitude'], 'Number');
            }
            if (data.hasOwnProperty('acquired_at')) {
                obj['acquired_at'] = ApiClient.convertToType(data['acquired_at'], 'Date');
            }
            if (data.hasOwnProperty('acquired_by')) {
                obj['acquired_by'] = ApiClient.convertToType(data['acquired_by'], 'String');
            }
            if (data.hasOwnProperty('weather')) {
                obj['weather'] = ApiClient.convertToType(data['weather'], 'String');
            }
            if (data.hasOwnProperty('resolution_x')) {
                obj['resolution_x'] = ApiClient.convertToType(data['resolution_x'], 'Number');
            }
            if (data.hasOwnProperty('resolution_y')) {
                obj['resolution_y'] = ApiClient.convertToType(data['resolution_y'], 'Number');
            }
            if (data.hasOwnProperty('resolution_unit')) {
                obj['resolution_unit'] = ApiClient.convertToType(data['resolution_unit'], 'String');
            }
            if (data.hasOwnProperty('compression')) {
                obj['compression'] = ApiClient.convertToType(data['compression'], 'String');
            }
            if (data.hasOwnProperty('manufacturer')) {
                obj['manufacturer'] = ApiClient.convertToType(data['manufacturer'], 'String');
            }
            if (data.hasOwnProperty('software')) {
                obj['software'] = ApiClient.convertToType(data['software'], 'String');
            }
            if (data.hasOwnProperty('color_space')) {
                obj['color_space'] = ApiClient.convertToType(data['color_space'], 'String');
            }
            if (data.hasOwnProperty('custom_id')) {
                obj['custom_id'] = ApiClient.convertToType(data['custom_id'], 'String');
            }
            if (data.hasOwnProperty('reference')) {
                obj['reference'] = ApiClient.convertToType(data['reference'], 'String');
            }
            if (data.hasOwnProperty('yaw')) {
                obj['yaw'] = ApiClient.convertToType(data['yaw'], 'Number');
            }
            if (data.hasOwnProperty('pitch')) {
                obj['pitch'] = ApiClient.convertToType(data['pitch'], 'Number');
            }
            if (data.hasOwnProperty('roll')) {
                obj['roll'] = ApiClient.convertToType(data['roll'], 'Number');
            }
            if (data.hasOwnProperty('focal_length')) {
                obj['focal_length'] = ApiClient.convertToType(data['focal_length'], 'Number');
            }
            if (data.hasOwnProperty('sensor_width')) {
                obj['sensor_width'] = ApiClient.convertToType(data['sensor_width'], 'Number');
            }
            if (data.hasOwnProperty('longitude')) {
                obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
            }
            if (data.hasOwnProperty('latitude')) {
                obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MetadataOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MetadataOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of MetadataOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['acquired_by'] && !(typeof data['acquired_by'] === 'string' || data['acquired_by'] instanceof String)) {
            throw new Error("Expected the field `acquired_by` to be a primitive type in the JSON string but got " + data['acquired_by']);
        }
        // ensure the json data is a string
        if (data['weather'] && !(typeof data['weather'] === 'string' || data['weather'] instanceof String)) {
            throw new Error("Expected the field `weather` to be a primitive type in the JSON string but got " + data['weather']);
        }
        // ensure the json data is a string
        if (data['resolution_unit'] && !(typeof data['resolution_unit'] === 'string' || data['resolution_unit'] instanceof String)) {
            throw new Error("Expected the field `resolution_unit` to be a primitive type in the JSON string but got " + data['resolution_unit']);
        }
        // ensure the json data is a string
        if (data['compression'] && !(typeof data['compression'] === 'string' || data['compression'] instanceof String)) {
            throw new Error("Expected the field `compression` to be a primitive type in the JSON string but got " + data['compression']);
        }
        // ensure the json data is a string
        if (data['manufacturer'] && !(typeof data['manufacturer'] === 'string' || data['manufacturer'] instanceof String)) {
            throw new Error("Expected the field `manufacturer` to be a primitive type in the JSON string but got " + data['manufacturer']);
        }
        // ensure the json data is a string
        if (data['software'] && !(typeof data['software'] === 'string' || data['software'] instanceof String)) {
            throw new Error("Expected the field `software` to be a primitive type in the JSON string but got " + data['software']);
        }
        // ensure the json data is a string
        if (data['color_space'] && !(typeof data['color_space'] === 'string' || data['color_space'] instanceof String)) {
            throw new Error("Expected the field `color_space` to be a primitive type in the JSON string but got " + data['color_space']);
        }
        // ensure the json data is a string
        if (data['custom_id'] && !(typeof data['custom_id'] === 'string' || data['custom_id'] instanceof String)) {
            throw new Error("Expected the field `custom_id` to be a primitive type in the JSON string but got " + data['custom_id']);
        }
        // ensure the json data is a string
        if (data['reference'] && !(typeof data['reference'] === 'string' || data['reference'] instanceof String)) {
            throw new Error("Expected the field `reference` to be a primitive type in the JSON string but got " + data['reference']);
        }

        return true;
    }


}

MetadataOut.RequiredProperties = ["altitude", "acquired_at", "acquired_by", "weather", "resolution_x", "resolution_y", "resolution_unit", "compression", "manufacturer", "software", "color_space", "custom_id", "reference", "yaw", "pitch", "roll", "focal_length", "sensor_width"];

/**
 * @member {Number} altitude
 */
MetadataOut.prototype['altitude'] = undefined;

/**
 * @member {Date} acquired_at
 */
MetadataOut.prototype['acquired_at'] = undefined;

/**
 * @member {String} acquired_by
 */
MetadataOut.prototype['acquired_by'] = undefined;

/**
 * @member {String} weather
 */
MetadataOut.prototype['weather'] = undefined;

/**
 * @member {Number} resolution_x
 */
MetadataOut.prototype['resolution_x'] = undefined;

/**
 * @member {Number} resolution_y
 */
MetadataOut.prototype['resolution_y'] = undefined;

/**
 * @member {String} resolution_unit
 */
MetadataOut.prototype['resolution_unit'] = undefined;

/**
 * @member {String} compression
 */
MetadataOut.prototype['compression'] = undefined;

/**
 * @member {String} manufacturer
 */
MetadataOut.prototype['manufacturer'] = undefined;

/**
 * @member {String} software
 */
MetadataOut.prototype['software'] = undefined;

/**
 * @member {String} color_space
 */
MetadataOut.prototype['color_space'] = undefined;

/**
 * @member {String} custom_id
 */
MetadataOut.prototype['custom_id'] = undefined;

/**
 * @member {String} reference
 */
MetadataOut.prototype['reference'] = undefined;

/**
 * @member {Number} yaw
 */
MetadataOut.prototype['yaw'] = undefined;

/**
 * @member {Number} pitch
 */
MetadataOut.prototype['pitch'] = undefined;

/**
 * @member {Number} roll
 */
MetadataOut.prototype['roll'] = undefined;

/**
 * @member {Number} focal_length
 */
MetadataOut.prototype['focal_length'] = undefined;

/**
 * @member {Number} sensor_width
 */
MetadataOut.prototype['sensor_width'] = undefined;

/**
 * @member {Number} longitude
 */
MetadataOut.prototype['longitude'] = undefined;

/**
 * @member {Number} latitude
 */
MetadataOut.prototype['latitude'] = undefined;






export default MetadataOut;

