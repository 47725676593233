/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class DataUploadStatus.
* @enum {}
* @readonly
*/
export default class DataUploadStatus {
    
        /**
         * value: "PENDING"
         * @const
         */
        "PENDING" = "PENDING";

    
        /**
         * value: "COMPUTING"
         * @const
         */
        "COMPUTING" = "COMPUTING";

    
        /**
         * value: "ERROR"
         * @const
         */
        "ERROR" = "ERROR";

    
        /**
         * value: "DONE"
         * @const
         */
        "DONE" = "DONE";

    

    /**
    * Returns a <code>DataUploadStatus</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/DataUploadStatus} The enum <code>DataUploadStatus</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

